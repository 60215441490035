// app.states.js

"use strict";

const contratacion = {
  name: "contratacion",
  url: "/?p=",
  component: "contratacion",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "contratacion.module" */ "@telecor-web/contratacion")
      .then(mod => $ocLazyLoad.inject(mod.CONTRATACION_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Contratación";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Contrata Energia El Corte Ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', '');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://ofertaempleados.energiaelcorteingles.es/contratacion');
  }],
  resolve: {
    config: ["contratacionConfig", (contratacionConfig) => {
      return contratacionConfig.getConfig();
    }],
    origen: [() => {
      return "employee";
    }],
    workplace: [() => undefined],
    agent: [() => undefined],
    teci: ["authService", (authService) => {
      return authService.getTeci();
    }],
    tariffNotFoundState: () => "errorProducto",
    pasos: () => "9"
  }
};

const errorProducto = {
  name: "errorProducto",
  url: "/errorProducto",
  component: "errorProducto",
  lazyLoad: ($transition$) => {
    const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
    return import(/* webpackChunkName: "errorProducto.module" */ "./components/error-producto/error-producto.module")
      .then(mod => $ocLazyLoad.inject(mod.ERROR_PRODUCTO_MODULE))
      .catch(err => {
        throw new Error("Ooops, something went wrong, " + err);
      });
  },
  onEnter: ["$document", ($document) => {
    $document[0].title = "Energía El Corte Inglés - Error";
    $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Contrata Energia El Corte ingles');
    $document[0].querySelector('meta[name="keyword"]').setAttribute('content', '');
    $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://ofertaempleados.energiaelcorteingles.es/errorProducto');
  }]
};

export { contratacion, errorProducto };
